import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageTitle from '../components/Blocks/PageTitle'

const PageApplyNow = ({ data, pageContext}) => {
    const { lang } = pageContext
    const pageTitle = (lang === 'fr') ? 'Applique maintenant' : 'Apply now'
    return (
        <Layout locale={lang}>
            <SEO title={pageTitle} />
            <PageTitle titleId="apply-now" />

            <section id='main' className='container'>
                <div className='row'>
                    <div className={'iframe-container'} style={{ display: 'flex', flex: 1, height: '1900px', overflow: 'hidden'}}>
                        <iframe frameBorder={0}
                                style={{ width: '100%' }}
                            src={'https://dev-18308-evolving-web.pantheonsite.io/contact/work-with-us?viewMode=iframe'} />
                    </div>

                </div>
            </section>
        </Layout>
    )
}

export default PageApplyNow